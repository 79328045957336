@import url('https://fonts.googleapis.com/css?family=Calibri:300,400,500,700&display=swap');

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../fonts/Inter-Regular.ttf) format('truetype');
}
body,
html {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

a {
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  a {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  a:hover {
    color: rgba(255, 255, 255, 0.87);
  }
}

.Inter-font-family {
  font-family: 'Inter', 'Roboto', sans-serif !important;
}

.loadingAnimation {
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-16px);
  }
}

@keyframes action {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-16px);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  text-align: center;
}

.inlineEditInput {
  border-color: #cccccc;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  padding: 0px 10px;
  margin-left: -10px;
  margin-top: -6px;
  margin-bottom: -6px;
  width: 100%;
}

.inline-form-select .MuiInputBase-root {
  border: none;
}

.inline-form-select .MuiInput-underline:before {
  border-bottom: none !important;
}

.inline-form-select .MuiInput-underline:after {
  border-bottom: none !important;
}

.inline-form-select .MuiInput-underline input {
  font-size: 14px;
  text-align: left;
  /* width: 75%;
  text-align: right !important;
  padding-right: 10px !important; */
}

.inline-form-select .MuiAutocomplete-inputRoot {
  padding-right: 20px !important;
  width: 75%;
  left: 26%;
  text-align: right;
}

.inline-form-select .MuiFormHelperText-root {
  /* text-align: left;
  padding-right: 20px !important;
  width: 75%; */
}

/* input[type="checkbox"] label {
  font-size: 14px;
}

input[type="checkbox"] {
  display: none;
} */

input[type='checkbox'] {
  display: none;
}

.boardsMenuSidebar {
  border-top: 1px solid #37474f;
  background-color: #132b41;
}

.MuiTableRow-hover:hover {
  background-color: rgba(25, 118, 210, 0.08) !important;
}

.MuiTableCell-head.MuiTableCell-paddingCheckbox {
  padding: 4px !important;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12) !important;
}

.clamp3lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.tox .tox-tbtn[title='It is a personalize content variable'] {
  color: #1976d2 !important;
}

.lookupsGrid td {
  padding: 0 !important;
}

.activity-note-title a,
.activity-note-title a:visited,
.activity-note-title a:active {
  color: rgb(0, 0, 238);
}

.MuiOutlinedInput-root:hover fieldset {
  border-color: #1976d2 !important;
}

.MuiNumberInput input {
  text-align: right;
}

.red-border {
  border: 1px solid red !important;
}

.integrations_icon {
  width: 26px;
  height: 26px;
}

.lms-search-input input {
  background: #193247 !important;
  border-radius: 10px !important;
  color: #cccccc !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.recharts-legend-item {
  cursor: pointer;
}

#lms {
  color: rgba(255, 255, 255, 0.6);
}

#lms .recharts-default-tooltip {
  background-color: rgb(9, 13, 17) !important;
  border-color: #ffffff20 !important;
}

#lms .MuiSkeleton-root {
  /* background-color: #1B2834; */
  background-color: #19324a;
}

#lms .MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.02), transparent);
}

/* #lms .css-1mhau4x{
  background-color: #193247 !important;
  } */

/*  input:-internal-autofill-selected{
  background-color: #193247 !important;
} */

#lms input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #193247 inset;
  /* Change the color to your own background color */
  -webkit-text-fill-color: #cccccc;
}

#list input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #082035 inset;
  /* Change the color to your own background color */
  -webkit-text-fill-color: #bfbfbf !important;
}

#list input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #082035 inset !important;
  /*your box-shadow*/
  -webkit-text-fill-color: #bfbfbf !important;
}

#lms .MuiSelect-select {
  color: white !important;
}

#lms input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #193247 inset;
  /*your box-shadow*/
  -webkit-text-fill-color: #cccccc;
}

#lms fieldset {
  /* height: 50px; */
  border-radius: 10px;
  border-color: rgba(239, 239, 239, 0.23) !important;
}

#lmsSlider .slick-slide {
  margin-left: 8px;
  margin-right: 8px;
  width: 549px !important;
}

#lms .slick-track {
  width: max-content !important;
}

#noteFilter fieldset {
  height: 50px !important;
  border-color: rgba(239, 239, 239, 0.23) !important;
}

#dashboardFilter fieldset {
  /* height: 50px; */
  border-color: rgba(239, 239, 239, 0.23) !important;
}

#globalFilter fieldset {
  height: 50px !important;
}

#globalSearch .MuiInputBase-root {
  background-color: #193247 !important;
  background: #193247 !important;
  border-radius: 8px;
}

.disable-pastdays > div > div > div > ul.MuiList-root :nth-child(2),
.disable-pastdays > div > div > div > ul.MuiList-root :nth-child(4),
.disable-pastdays > div > div > div > ul.MuiList-root :nth-child(5),
.disable-pastdays > div > div > div > ul.MuiList-root :nth-child(7),
.disable-pastdays > div > div > div > ul.MuiList-root :nth-child(9) {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.35);
}

.MuiTableRow-root .MuiTableCell-root:first-child {
  padding-left: 12px !important;
}

.MuiTableRow-root .MuiTableCell-root.MuiTableCell-paddingCheckbox:first-child {
  padding-left: 4px !important;
}

.simplebar-scrollbar:before {
  background: #4a7093 !important;
  /* opacity: 1 !important; */
  transition: opacity 1s 0.5s linear;
}

.lmsContainer .recharts-default-tooltip {
  background-color: #0c1721 !important;
}
.seq-slider .slick-slide {
  width: 192px !important;
}
.seq-slider .slick-track {
  width: 1800px !important;
}
.center-status > span {
  justify-content: center;
}

/* sequence ai writer text editor form ui change */

/* Customize the toolbar buttons */
/*.tox .tox-toolbar {*/
/*  background-image: none !important;*/
/*}*/

.tox .tox-toolbar__group {
  padding: 0 !important;
  margin-right: 0px !important;
}
.tox-toolbar__primary {
  padding-left: 5px !important;
}

.ai-content-view .tox-editor-header {
  box-shadow: none;
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.ai-linkedin-content-view .ai-content-view .tox-editor-header {
  box-shadow: none;
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.ai-content-view .tox-tinymce {
  border: 0px !important;
}

.ai-content-view .tox-editor-container {
  background: #ffffff !important;
}

.ai-content-view .tox-edit-area {
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #0000001f;
}
.ai-content-view .tox-edit-area:before {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-width: 1px !important;
}

.ai-content-view .MuiOutlinedInput-notchedOutline {
  border-color: #0000001f;
}
.ai-content-view .MuiInputBase-root {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ai-content-view .MuiFormControl-root {
  margin: 0 !important;
}

.custom-autocomplete .MuiAutocomplete-input {
  padding: 0px !important;
  font-size: 14px;
}

.ai-content-view .tox-toolbar__group {
  gap: 8px;
}
.ai-content-view .tox-toolbar__group > button[data-mce-name='aiemailwrite'] {
  width: 111px !important;
  background: linear-gradient(
    236deg,
    rgba(255, 212, 128, 0.5) 10.71%,
    rgba(242, 64, 164, 0.5) 58.14%,
    rgba(25, 118, 210, 0.5) 93.71%
  );
  color: #1976d2;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 3px;
  box-shadow: 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.15), 0px 1px 0.75px 0px rgba(0, 0, 0, 0.05);
}

.ai-content-view
  .tox-toolbar__group
  > button[data-mce-name='aiemailwrite']
  > .tox-tbtn__select-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin: 0;
}

.ai-content-view .tox-toolbar__group > button[data-mce-name='aiemailwrite']:hover {
  background: linear-gradient(
    236deg,
    rgba(255, 212, 128, 0.5) 10.71%,
    rgba(242, 64, 164, 0.5) 58.14%,
    rgba(25, 118, 210, 0.5) 93.71%
  );
  color: #1976d2;
}
.ai-content-view .tox-toolbar__group > button {
  margin: 0 !important;
  cursor: pointer;
}

.ai-content-view .tox-toolbar__group > button[data-mce-name='overflow-button'] > span > svg {
  transform: rotate(90deg);
}
